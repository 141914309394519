<template>
  <div style="margin: 20px">
    <el-card>
      <el-form :model="form" v-loading="loading" :rules="rules" ref="form">
        <el-form-item label="Наименование" prop="ebosp_name">
          <el-input v-model="form.ebosp_name"></el-input>
        </el-form-item>
        <el-form-item label="Сокращение">
          <el-input v-model="form.short_name"></el-input>
        </el-form-item>
        <el-form-item label="Код">
          <el-input v-model="form.code"></el-input>
        </el-form-item>
        <el-form-item label="Вице-губернатор">
          <el-select
            v-model="form.vice_governor_id"
            clearable
            style="width: 100%">
            <el-option
              v-for="(item) in governors"
              :key="item.id"
              :label="item.title"
              :value="item.id" />
          </el-select>
        </el-form-item>
        <el-row>
          <el-col :span="4">
            <el-form-item label="Оператор по КПР">
              <el-checkbox v-model="form.operator_on_pki" />
            </el-form-item>
            <el-form-item label="Оператор по КП">
              <el-checkbox v-model="form.operator_on_kp" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-form-item label="Оцениваемый по КПР">
              <el-checkbox v-model="form.ebosp_on_pki" />
            </el-form-item>
            <el-form-item label="Оцениваемый по КП">
              <el-checkbox v-model="form.ebosp_on_kp" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button type="primary" @click="save" :disabled="lockSaveButton">
            {{ isCreate ? 'Создать' : 'Сохранить' }}
          </el-button>
          <el-button v-if="form.is_deleted && !isCreate" type="success" @click="recoverActivity"
                     :disabled="lockRecoveryButton">Восстановить
          </el-button>
          <el-button v-if="!form.is_deleted && !isCreate" type="danger" @click="deleteActivity"
                     :disabled="lockDeleteButton">Удалить
          </el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 10px">
      <label>История изменений</label>
      <simple-table style="margin-top: 10px" name="historyActivity" :columns="columns" :list="historyData" v-loading="loading"/>
    </el-card>
  </div>
</template>

<script>
import {
  getIogv,
  saveIogv,
  deleteIogv,
  recoverIogv,
  getIogvHistory
} from '@/api/dictionaries'
import SimpleTable from '@/components/SimpleTable'
import { getViceGovernorsDict } from '@/api/catalogs'

export default {
  components: {
    SimpleTable
  },
  data () {
    return {
      loading: false,
      lockSaveButton: false,
      lockDeleteButton: false,
      lockRecoveryButton: false,
      isCreate: false,
      historyData: [],
      governors: [],
      columns: [
        {
          field: 'action_tstamp',
          type: 'date',
          label: 'Время изменения'
        },
        {
          field: 'value.ebosp_name',
          label: 'Наименование'
        },
        {
          field: 'value.short_name',
          label: 'Сокращение'
        },
        {
          field: 'value.code',
          label: 'Код'
        },
        {
          field: 'vice_governor_fio',
          label: 'Вице-губернатор'
        },
        {
          field: 'editor_fio',
          label: 'Кто изменил'
        },
        {
          field: 'value.is_deleted',
          label: 'Удален'
        }
      ],
      form: {
        id: null,
        ebosp_name: null,
        short_name: null,
        code: null,
        vice_governor: null,
        vice_governor_id: null,
        ebosp_on_pki: false,
        ebosp_on_kp: false,
        operator_on_pki: false,
        operator_on_kp: false
      },
      rules: {
        ebosp_name: [
          {
            validator: (rule, value, callback) => {
              if (value?.trim() === '' || !value) {
                callback(new Error('Не заполнено обязательное поле'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  mounted () {
    getViceGovernorsDict({ is_deleted: false }).then((response) => {
      this.governors = response.data.data
      if (this.form.vice_governor_id && this.governors.filter(x => x.id === this.form.vice_governor_id).length === 0) {
        this.governors.push(this.form.vice_governor)
      }
    })
    if (this.$route.name.includes('create')) {
      this.isCreate = true
    } else {
      this.fetchData()
    }
  },
  methods: {
    fetchData () {
      this.loading = true
      getIogv(this.$route.params.id).then(response => {
        this.form = response.data
      }).finally(() => {
        this.loading = false
      })
      getIogvHistory(this.$route.params.id).then(response => {
        this.historyData = response.data.model
      })
    },
    save () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.lockSaveButton = true
          saveIogv(this.form).then((response) => {
            this.$message({
              message: 'Успешно сохранено!',
              type: 'success'
            })
            if (this.isCreate) {
              this.$router.push({ name: this.$route.name.replace('create', 'view'), params: { id: response.data.id } })
            } else {
              this.fetchData()
            }
          }).finally(() => {
            this.lockSaveButton = false
          })
        }
      })
    },
    deleteActivity () {
      this.lockDeleteButton = true
      deleteIogv(this.form.id).then(() => {
        this.$message({
          message: 'Успешно удалено!',
          type: 'success'
        })
        this.fetchData()
      }).finally(() => {
        this.lockDeleteButton = false
      })
    },
    recoverActivity () {
      this.lockRecoveryButton = true
      recoverIogv(this.form.id).then(() => {
        this.$message({
          message: 'Успешно восстановлено!',
          type: 'success'
        })
        this.fetchData()
      }).finally(() => {
        this.lockRecoveryButton = false
      })
    }
  }
}
</script>

<style scoped>

</style>
